body::-webkit-scrollbar {
  display: none;
}

body {
  overflow: auto !important;
  overflow-y: scroll;
  -ms-overflow-style: none;
  font-family: 'Google Sans Text', sans-serif, 'Roboto', 'Helvetica', 'Arial' !important;
  height: 100%;
  font-size: 1rem;
  line-height: 1.35;
  background-color: #fff;
}

*,
:after,
:before {
  box-sizing: border-box;
}

.rootBody2 {
  margin-top: 0px !important;
  padding-top: 0px !important;

  height: 100% !important;
  background-color: transparent !important;
  margin-top: 75px;
  height: 450px !important;
  width: 600px !important;
  display: contents;
}

.App {
  text-align: left;
  background-color: #fdfdfe;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* margin-top: 118px; */
  aspect-ratio: '1/1';
}

@media (min-width: 1501px) {
  .App {
    text-align: left;
    background-color: #fdfdfe;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
  }
}
.App2 {
  text-align: left;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  aspect-ratio: '1/1';
}
.bhold {
  height: 100% !important;
}
.flex {
  display: flex;
}
.flex2 {
  display: flex;
  width: 70px;
  justify-content: right;
  padding-right: 7px;
}
a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

button {
  cursor: pointer;
}

.App-header {
  background-color: #282c34;
  height: 45px;
  display: inline-flex;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
}
.inline {
  display: inline-flex;
}
.block {
  display: block;
}
.centerJustified {
  text-align: center;
}
.ulDisc {
  list-style-type: disc;
}
.ulCircle {
  list-style-type: circle;
}
.footer2 {
  text-align: center;
  font-size: 12px;
  background-color: transparent;
}
.versionNum {
  text-align: right;
  width: 100%;
  width: 100%;
  margin-right: 12px;
}
#top-scroll {
  margin-top: 40px;
}
.meeting-title {
  display: none;
}
.av-preview-container {
  width: 300px !important;
  height: 178px !important;
}

.width100vw {
  width: 100vw;
  max-width: 1500px;
  min-height: 60vh;
  -ms-overflow-style: none;
}
.webinarDescriptionText {
  color: black;
  overflow: scroll;
  height: 272px;
}

.box-shadow {
  box-shadow: 0 2px 7px rgba(178, 178, 178, 0.1);
}
/* #root {
  height: 100% !important;
} */
.webinarPlayerData {
  padding: 6px;
  position: relative;
  width: 250px;
  justify-content: right;
  height: 438px;
  background-color: white;
  z-index: 3;
  margin-left: calc(50vw + 177px);
  top: 20px;
  overflow: hidden;
}
#zmmtg-root {
  display: none;
  background-color: white !important;
  margin: auto auto;
  height: 100% !important;
  /* max-height: 40vh; */
  width: 100% !important;
  top: 0px !important;
  /* top: 40px !important; */
  /* margin-left: calc(50vw - 475px) !important;*/
}
#content_container {
  background: transparent !important;
  height: 100% !important;
  width: 100% !important;
}
.meeting-app {
  height: 100% !important;
  width: 100% !important;
}
.meeting-app div:first-of-type {
  height: 100%;
  width: 100%;
}
.meeting-client-inner div:first-of-type {
  height: auto;
  width: auto;
}

#wc-loading {
  height: 100% !important;
  width: 100% !important;
}
.root-inner {
  height: 100% !important;
  width: 100% !important;
}
.main-content {
  height: 100% !important;
  width: 100% !important;
}

.gallery-video-container__wrap {
  height: 100% !important;
  width: 100% !important;
}
.meeting-client-inner {
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
  top: 0;
  left: 0;
  margin-top: 0px;
}
.webinar-client-inner {
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
  top: 0;
  left: 0;
  margin-top: 0px;
}
.meeting-client {
  position: relative !important;
}
#wc-leave {
  height: 100% !important;
  width: 100% !important;
}
.sharee-container__canvas-outline {
  width: 100% !important;
  display: none;
  top: -3px;
  left: -3px;
}
.zm-modal-legacy {
  display: none;
}
#main-video {
  width: 100%;
  height: 100%;
}
.gallery-video-container__main-view {
  margin-top: 0px !important;
}
.join-dialog {
  /* height: 100% !important; */
  width: 100% !important;
}

.embedIFrame {
  position: relative;
  top: -470px;
  left: calc(50vw - 507px);
  height: 440px;
  width: 684px;
  z-index: 4;
  overflow-y: hidden;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
.embedIFrame html,
.embedIFrame body {
  overflow-y: hidden;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  position: fixed;
}
#rootBody {
  position: fixed;
}
.embedIFrame2 {
  position: relative;
  display: block;
  /* left: 0px; */
  height: 440px;
  width: 684px;
  z-index: 4;
  margin: auto auto;
}
.embedIFrame3 {
  position: relative;
  display: block;
  top: -30px;
  margin-bottom: -30px !important;
  /* left: 0px; */
  height: 448px;
  width: 100%;
  z-index: 4;
  margin: auto auto;

  /* position: relative;
  display: block;
  top: -30px;
  height: 470px;
  width: 684px;
  z-index: 4;
  margin: auto auto; */
}
.vsc-initialized {
  min-width: 100% !important;
}

.sharee-container__viewport {
  height: 398px !important;
  width: 700px !important;
}
.profile2 {
  position: relative;
  top: -415px;
  height: 555px;
  max-height: 100%;
  width: 100%;
  display: flex;
}
.playerPreviewHolder {
  top: 22px;
  margin: auto;
  height: 390px;
  width: 1100px;
  display: inline-flex;
  position: relative;
}
/* .playerPreviewImg {
  height: 100%;
  margin: auto;
  position: relative;
  left: 0;
  width: 50%;
  object-fit: cover;
}
.playerPreviewImg2 {
  height: 100%;
  margin: auto;
  position: relative;
  right: 0;
  width: 50%;
  object-fit: cover;
} */
.custom-dropdown-menu.dropdown-menu {
  display: none;
}
.css-a1o2vn {
  padding-top: 42px;
}
#WUFrame {
  margin-top: -75px;
  width: calc(100% + 40px);
  height: calc(100vh);
  border: none;
  position: relative;
}
.loginModalTitleText{

  margin-top: 0px;
  margin-bottom: 5px;

}
.playerPreviewImgMain {
  margin: auto;
  margin-top: -26px;
  position: relative;
  width: 100%;
  height: 440px;
  object-fit: contain;
  box-shadow: 0px 0px 1rem #000;
  min-width: 50px;
  z-index: 2;
}
.playerPreviewImg {
  margin: auto;
  position: relative;
  width: 40px;
  height: 100%;
  object-fit: contain;
  box-shadow: 0px 0px 1rem #000;
  clip-path: inset(-50px 0px -50px -50px);

  min-width: 50px;
  object-fit: cover;
  object-position: 10% 100%;
}
.playerPreviewImg2 {
  margin: auto;
  position: relative;
  width: 55px;
  height: 100%;
  object-fit: contain;
  box-shadow: 0px 0px 1rem #000;
  clip-path: inset(-50px -50px -50px 0px);
  z-index: 1;
  min-width: 50px;
  object-fit: cover;
  object-position: 90% 100%;
}
.playerPreviewImg3 {
  margin: auto;
  margin-top: 32px;
  position: relative;
  width: 32px;
  height: 85%;
  object-fit: contain;
  box-shadow: 6px 0px 17px #888;
  object-fit: cover;
  object-position: 90% 100%;
}
.playerPreviewImg4 {
  margin: auto;
  margin-top: 30px;
  position: relative;
  width: 32px;
  height: 85%;
  object-fit: contain;
  box-shadow: -6px 0px 17px #888;
  object-fit: cover;
  object-position: 10% 100%;
}
.playerPreviewImgContainer {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: flex;
}

.container__viewport {
  margin-right: 264px !important;
}
.sharee-container__viewport {
  margin: 0px !important;
}
.sharee-container__canvas {
  width: auto;
  height: 450px;
  /* margin-left: 6px;
  margin-top: 4px; */
}
.video-share-layout {
  height: 100% !important;
  width: 100% !important;
}

.numLabelHolder {
  display: inline-flex;
  width: 25px;
  min-height: 30px;
  text-align: right;
}

.timep {
  margin-top: 10px;
  margin-bottom: 0px;
}
.numLabel {
  display: inline-flex;
  margin: auto;
  margin-right: 16px;
  margin-left: auto;
}
.subhead {
  margin-top: auto;
  margin-bottom: 4px;
  margin-left: 4px;
  font-size: 16px;
}
.headerButton {
  display: inline;
  color: white;
  font-size: 3.5vw;
  font-family: 'Roboto', sans-serif;
}
.headerButton2 {
  margin-left: 30px;
  font-size: 25px;
  bottom: 2px;
  /* right: 0px; */
  position: relative;
  display: inline;
  color: white !important;
}
.headerButtonContainer {
  display: inline-flex;
  align-items: left;
  justify-content: left;
  width: 98%;
  margin: auto;
}
span.tv-logo {
  font: normal normal 900 30px 'Roboto', sans-serif;
  font-size: inherit;
}
.homePageText {
  font-size: 1.5em;
  margin-top: 0px;
}
#upsellGif {
  width: 40vw;
  height: 35vw;
  border-radius: 5px;
  /* margin-right: -20px; */
  margin-top: 10px;
  margin-left: 12px;
}
#homePageIMG2 {
  width: 40vw;
  height: 30vw;
  border-radius: 5px;
  margin: auto 26px;
  margin-left: 0px;
}
.homePageFlex {
  display: inline-flex;
}
@media (max-width: 800px) {
  .homePageFlex {
    display: grid;
    width: 100%;
  }
  #upsellGif {
    margin: auto auto;
    width: 70vw;
    height: 60vw;
  }
  #homePageIMG2 {
    width: 60vw;
    height: 40vw;
    border-radius: 5px;
    margin: auto auto;
    margin-left: auto;
    margin-top: 26px;
  }
}
.navButtons {
  display: contents;
}
.navModalButton {
  display: none !important;
}
@media (max-width: 775px) {
  .navButtons {
    display: none;
  }
  .navModalButton {
    display: block !important;
  }
}
.testimonial {
  margin: 10px;
}

.navButtons2 {
  display: block;
  background: white;
  border-radius: 4px;
  margin-top: 66px;
}
.navLinks {
  color: white;
}
.navPath {
  display: flex;
  margin-left: 65px;
  width: 348px;
}
.pathItem {
  margin: 3px;
  margin-top: 5px;
  margin-bottom: 4px;
  white-space: nowrap;
}
.CategoryHolder {
  width: 400px;
  justify-content: left;
  text-align: left;
  margin: auto;
}
.textHolder {
  margin: auto;

  min-width: 140px;
}
.textHolder2 {
  min-width: 60px;
  margin-right: 5px;
  display: inline-flex;
}
.timeFrameSelector {
  display: flex;
  margin-left: auto;
  margin-top: -2px;
  margin-bottom: -56px;
  width: 200px;
}
.timeFrameItem {
  margin: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.timeFrameItemActive {
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
  margin: 2px;
  margin-top: 15px;
  margin-bottom: 5px;
  background-color: lightgrey;
  border-radius: 100px;
}
.webinarTable {
  max-width: 100%;
  margin: auto;
  min-height: 50px;
}
.webinarText {
  white-space: pre-line;
}
.webinarBanner {
  height: 350px;
  max-width: 90%;
}
.carouTitle {
  text-decoration: none !important;
  padding-left: 2px;
  margin: 0px;

  color: black;
}
.carouHost {
  text-decoration: none !important;
  padding-left: 2px;
  margin: 0px;

  color: grey;
}
.carouImage a {
  color: #ffffff;
  text-decoration: none;
}
.carouImage {
  border: 1px solid grey;
}
.webinarLogo {
  max-width: 21%;
  margin: 10px;
  margin-left: -7px;
}
.App-link {
  color: #61dafb;
}
.wc-loading {
  height: 100%;
}
.join-meeting {
  height: 10% !important;
  max-height: 10% !important;
}

.loading-main {
  height: 100%;
}
.loading-img {
  margin-top: 25%;
}
.loading {
  margin: auto auto;
  z-index: 7;
  height: 0px;
  width: 530px;
}
.loadingPositioner {
  padding: 16px;
  width: 45px;
  margin: auto;
}
.logoImage {
  margin: auto;
  margin-top: 4px;

  max-height: 75px;
  width: 75px;
}
.player2Spacer {
  margin-top: 12px;
  height: 0px;
}
.player3Spacer {
  top: 0px;
}
html {
  /* min-width: 100% !important; */
  height: 100%;
  overflow: auto;
}
.audio-option-menu__pop-menu {
  bottom: 116px !important;
  left: 143px !important;
  position: relative;
}
.last-chat-message-tip__container {
  bottom: 104px !important;
  position: relative;
}

.hitp {
  margin-bottom: 6px;
  margin-top: 2px;
  color: black;
}

.hitelement {
  margin-bottom: 20px;
}
.css-k76fqu {
  max-height: 56px;
}

/* .tierText {
  margin-top: 1px;
}
.tierSpacer {
  margin-left: 20px;
}
.priceText {
  margin-top: 1px;
} */

#outlined-basic2 {
  padding: 1px;
}

.justifyLeft {
  text-align: left;
  margin-left: 50px;
  margin-right: 50px;
}
.timeText {
  font-size: 14px;
  margin: 0px;
  color: #515151;
}
.closePopup {
  margin-left: 95.5%;
  margin-bottom: -14px;
}
#confirmPop {
  background-color: #000000b8;
  position: fixed;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 0px;
  width: auto;
  z-index: 2;
  margin-left: auto;
  pointer-events: none;
}
.confirmSpacer {
  position: fixed;
  left: calc(50% - 270px);
  top: calc(50% - 140px);
  /* margin: auto; */
  z-index: 100;
}
.confirmHolder {
  position: fixed;
  /* left: 0; */
  /* right: 0; */
  /* width: 100%; */
  top: 0px;
  bottom: 0px;
  /* width: auto; */
  margin-left: auto;
}
.confirmHolder2 {
  margin-top: 150px;
  z-index: 10;
}

#submitConfirm {
  padding: 16px;
  margin: auto auto;
  width: 650px;
  max-width: 100vw;
  z-index: 7;
  position: relative;
  height: auto;
}
#submitConfirmHolder {
  position: relative;

  height: 0px;
  z-index: 2;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#searchbox {
  margin-left: 10px;
  margin-right: 10px;
}

ul {
  list-style-type: none;
}
.css-1yo9slo-MuiToolbar-root {
  min-height: 64px !important;
}

#webinars-box-container {
  display: flex;
  flex-wrap: wrap;
  min-width: 30%;
  list-style: none;
  margin: 0px -0.5rem;
}

/* .MuiDrawer-paper {
  padding-left: 20px;
} */

.scroll-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 15px;
  background: none;
  color: white;
  cursor: pointer;
  border-radius: 10px;
  border: none;
}

.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding-right: 13vw;
}

.terms-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.terms-title {
  width: 100%;
  font-weight: 600;
}

.terms-section {
  width: 100%;
  white-space: pre-wrap;
}

.description-scroll::-webkit-scrollbar {
  display: none;
}

.tick {
  flex-wrap: 'wrap !Important';
  display: flex;
  flex-direction: row;
  font-size: calc(16px + 0.2vw);
  flex: 1 4 auto;
  min-width: 0px;
  max-width: 100%;
  align-items: 'flex-end';
}

.tick-flip {
  margin-left: 0.03em !important;
  margin-right: 0.03em !important;
  flex: 1 4 auto;
}

.tick-icon {
  flex-shrink: 4;
}

.tick-label {
  margin: auto;
  padding: 0px 18px;
  flex-shrink: 4;
  min-width: 0px;
  font-size: 16px;
  border-bottom: '1px solid white';
}

.tick-items {
  display: 'flex';
  flex-wrap: 'nowrap';
}

.status-response-open {
  margin: 0 0 0 5px;
  color: green;
}
.status-response-taken {
  margin: 0 0 0 5px;
  color: red;
}

.host-webinar-collection {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 4vw;
  padding-top: 0;
}

.collection-title {
  margin: 10px 0px 20px 0px;
}
/* #card-container {
  min-width: 50%;
  max-width: 50%;
} */
/* #ind-webinar-container {
  flex: 1 0 auto;
  -webkit-box-flex: 1;
  width: 30rem;
  max-width: 50%;
  padding: 0px 0.5rem;
}
#ind-webinar-desc {
  display: inline-flex;
} */
.libraryImg {
  width: 100%;
  height: 180px;
  /* max-height: 292px; */
  overflow: hidden;
  object-fit: contain;
  object-position: center;
}
.libraryImg2 {
  width: 100%;
  height: 180px;
  overflow: hidden;
  object-fit: cover;
  object-position: top;
}
.libraryLower {
  position: absolute;
  margin-top: auto;
  margin-bottom: 12px;
  bottom: 0px;
  width: 276px;
}

#wrapper {
  position: relative;
  max-width: 1000px;
  min-height: 100%;
  background-color: #fdfdfe;
  width: 100%;
}

@media (max-width: 999px) {
  #wrapper {
    padding-top: 84px;
    position: relative;
    max-width: 1000px;
    min-height: 100%;
    padding: 0 0 100px;
    background-color: #fdfdfe;
    width: 100%;
  }
}

.page-container {
  padding-bottom: 40px;
  min-height: 640px;
}

@media (max-width: 999px) {
  .page-container {
    min-height: inherit;
  }
}

.manage-event {
  margin-bottom: 150px;
  display: flex;

  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
}

.events-outer-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  background-color: #fdfdfe;
  width: 100%;
  max-width: 1000px;
}

.confirmation-background-color {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 40px;
}

.confirmation-font-color {
  display: flex;
  flex-direction: column;
}

/* .manage-event {
  padding-top: 35px;
  margin-bottom: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
} */

.events-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.events-border {
  border-bottom: 1px solid #eff3f6;
  margin-bottom: 20px;
}

.events-header-section {
  display: flex;
  align-items: flex-start;
}

.events-header-title {
  font-weight: 700;
  font-size: 20px;
}

.events-tabs-wrapper {
  width: 100%;
  border-bottom: 1px solid #eff3f6;
  margin: 20px 0px 40px 0px !important;
}

.events-header-tabs {
  margin-left: 10px;
  display: flex;
  align-items: flex-start;
  height: 55px;
  padding: 4px 10px 0px 10px;
  padding-top: 4px;
}

.event-header-filter-title {
  color: #929292;
  font-size: 15px;
  font-weight: 500;
  transition: 0.3s;
  text-transform: capitalize;
}

.events-header-filter-container {
  height: 100%;
  display: flex;
  border: 2px solid transparent;
  padding: 0 13px;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-property: all;
  text-transform: capitalize;
  background: transparent;
  position: relative;
}

.events-border {
  border-bottom: 1px solid #eff3f6;
  margin-bottom: 20px;
}

.event-header-filter-title {
  color: #929292;
  font-size: 15px;
  font-weight: 500;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-property: all;
  text-transform: capitalize;
}

.event-header-filter-title:hover {
  color: #1976d2;
  cursor: pointer;
}

.--activated {
  border-bottom: 2px solid #1976d2;
}

.event-header-filter-title.--activated {
  color: #1976d2;
  border: none;
}

.events-header-button-container {
  display: flex;
  align-items: center;
}

.--activated.all {
  color: #1976d2;
}
.--activated.standard {
  color: #1976d2;
}
.--activated.series {
  color: #1976d2;
}
.--activated.automated {
  color: #1976d2;
}
.--activated.archived {
  color: #1976d2;
}

.--activated:hover {
  color: #1976d2;
}

.ripple-button {
  border-radius: 4px;
  border: none;
  margin: 8px 0px 8px 8px;
  padding: 7px 12px;
  background: #1976d2;
  color: #fff;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.ripple-button > .ripple {
  width: 20px;
  height: 20px;
  position: absolute;
  background: #63a4ff;
  display: block;
  content: '';
  border-radius: 9999px;
  opacity: 1;
  animation: 0.9s ease 1 forwards ripple-effect;
}

@keyframes ripple-effect {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(10);
    opacity: 0.375;
  }
  100% {
    transform: scale(35);
    opacity: 0;
  }
}

.ripple-button > .content {
  position: relative;
  z-index: 2;
}

.sort-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.sort-events-search-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  color: #929292;
  text-align: right;
  align-items: center;
}

.sort-by-title {
  font-size: 14px;
  color: black;
}

.events-sort-by {
  min-width: auto;
  font-weight: 600;
}

.sort-drop-down-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}

.chevron-sort-by {
  display: flex;
  flex-direction: row;
}

/* .drop-down-container {

} */
.drop-down-inner-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search-webinars-container {
  position: relative;
}

.search-container {
  position: relative;
  display: inline-block;
}

.search-container input {
  height: 40px; /* Adjust as needed */
  padding-right: 40px; /* Make room for the search icon */
}

.search-input {
  outline: none;
  height: 40px;
  padding-right: 40px;
  padding-left: 20px;
  box-sizing: border-box;
  border: 1px solid #e8ecf3;
  color: rgba(0, 0, 0, 0.45);
  border-radius: 5px;
}

.search-input::placeholder {
  color: #b9c3cc;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(0, 0, 0, 0.45);
}
.search-icon2 {
  margin-top: 0px;
  top: -4px;
  position: relative;
  height: 24px;
  margin-bottom: -10px;
}

.planHolder {
  border: solid grey 3px;
  border-radius: 12px;
  width: 490px;
  padding: 26px;
  padding-top: 0px;
  padding-bottom: 12px;
  margin: 10px;
  margin-left: calc(50vw - 245px);
  z-index: 1;
}
.pricingHeader {
  text-align: center;
}
.priceText {
  margin-bottom: -6px;
  margin-bottom: 2px;
  margin-top: 12px;
  text-align: center;
}

.tierBoxTitle {
  margin-bottom: 2px;
  text-align: center;
}
.lightGreyBG {
  background: lightgrey;
  height: 52px;
}
.whiteBG {
  height: 52px;
}
.pricingBgSpacer {
  position: relative;
  width: calc(100% + 32px);
  left: -16px;
  height: 200px;
  margin-bottom: -200px;
  z-index: 0;
  top: 231px;
}
.planFieldLabels {
  padding-top: 246px;
  margin-bottom: 29px;
  z-index: 1;
}
.pricingStat {
  height: 31px;
  margin-bottom: 20px;
  text-align: center;
}
.pricingStatTitle {
  height: 31px;
  margin-bottom: 20px;
}
.pricingCheck {
  height: 31px;
  position: relative;
  top: 0px;
  margin-right: 8px;
}

.AttCapHolder {
  display: inline-flex;
  border: 1px solid rgb(196, 196, 196);
  border-radius: 32px;
}
.AttCapOption {
  padding: 12px;
  border-radius: 32px;
}
.AttCapOption:hover {
  background-color: #1976d2 !important;
  color: white !important;
}
.webinars-search-total {
  color: #929292;
  min-width: 77px;
  display: inline-block;
  text-align: center;
  margin-right: 15px;
  font-size: 14px;
}

.webinar-block-container.box-shadow {
  box-shadow: 0 2px 7px rgba(178, 178, 178, 0.1);
}

.webinar-block-container {
  display: flex;
  margin-bottom: 20px;
  border: 1px solid #eff3f6;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #fff;
}

.webinar-block-content.event {
  padding: 0;
}

.webinar-block-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 30px;
  width: 100%;
  position: relative;
}

.webinar-block-left {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.webinar-block-left.event {
  padding: 30px 0 30px 33px;
  cursor: pointer;
  border-right: 1px solid #eff3f6;
}

.webinar-block-right {
  display: flex;
  align-items: center;
}

.webinar-block-right.event {
  display: flex;
  align-items: center;
  padding: 30px;
}

.webinar-info {
  display: flex;
  flex-direction: column;
  max-width: 90%;
}

.webinar-thin {
  font-size: 12px;
  color: #9fa6ad;
  line-height: 18px;
  letter-spacing: 0.4px;
}

.webinar-header {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
}

.webinar-block-title {
  text-overflow: unset;
  white-space: normal;
  overflow: auto;
  max-width: 100%;
}

.webinar-info-topic,
.webinar-info-date {
  width: 48%;
}

.webinar-block-stats {
  color: #929292;
  font-size: 13px;
}

.webinar-options {
  position: relative;
}

.menu-item-event-options {
  color: #929292 !important;
}

.webinar-options-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  cursor: pointer;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
  border: 1px solid #eff3f6;
  height: 34px;
  width: 34px;
  padding: 0;
  border-radius: 5px;
}

.event-block-wrapper:hover {
  box-shadow: 0 10px 15px -10px rgba(30, 45, 62, 0.21),
    0 5px 40px -10px rgba(31, 44, 60, 0.1);
  transition: 0.3s;
}

.Box:hover {
  box-shadow: 0 10px 15px -10px rgba(30, 45, 62, 0.21),
    0 5px 40px -10px rgba(31, 44, 60, 0.1);
  transition: 0.3s;
}

.event-links-breadcrumb-container {
  line-height: normal;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  margin-bottom: 11px;
}

.event-links-breadcrumb-container span {
  color: #42454b;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.event-breadcrumb {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.event-links-breadcrumb-container span {
  color: #42454b;
}

.event-breadcrumb-link:hover {
  color: #1976d2;
  border-bottom: 1px solid #1976d2;
  cursor: pointer;
}

.event-manage-header-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.event-manage-area {
  display: flex;
  align-items: center;
}

.event-manage-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 1px;
  color: #2c3336;
  padding-right: 5px;
}

.event-manage-options {
  position: relative;
}

.event-options {
  width: 34px;
  height: 34px;
  border: 1px solid #9fa6ad;
  border-radius: 5px;
}

.edit-topic-icon {
  cursor: pointer;
  display: flex;
  align-self: flex-start;
}

.session-header-tabs {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  border: 1px solid #eff3f6;
  margin-bottom: 40px;
  border-radius: 1px;
}

.session-tab-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-size: 16px;
  color: #929292;
  flex: 1;
  height: 100%;
  background-color: transparent;
  border: 0;
  border-right: 1px solid #eff3f6;
  cursor: pointer;
}

.session-tab-buttons:hover {
  color: #1976d2;
}

.session-tab-buttons.--activated {
  color: #1976d2;
  border-bottom: 3px solid #1976d2;
}

.customize-event-container {
  border-radius: 5px;
  background-color: #fff;
}

.summary-container {
  border: 1px solid #e8ecf3;
  border-radius: 5px;
  background-color: #fff;
}

.summary-content {
  display: flex;
  flex-direction: column;
}

.summary-filter-container {
  border-bottom: 1px solid #e8ecf3;
}

.summary-filter-header {
  display: flex;
  height: 80px;
}

.summary-filter-buttons {
  padding-left: 15px;
  display: flex;
  height: 72px;
  padding-top: 20px;
}

.summary-filter-button {
  height: 100%;
  color: #2c3336;
  display: flex;
  border: 2px solid transparent;
  padding: 0 13px;
  transition: 0.3s;
  user-select: none;
  background: transparent;
}

.summary-filter-button:hover {
  color: #929292;
}

.summary-filter-button.active {
  border-bottom: 2px solid #2c3336;
}
.summary-filter-button.active:hover {
  color: #2c3336;
}

.summary-schedule {
  cursor: auto;
  display: flex;
  align-items: center;
  margin: 0 16px 0 auto;
}

.summary-schedule-button {
  border: 1px solid #dee4e8;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #1976d2;
  background: #fff;
  padding: 9px 16px;
  display: flex;
  align-items: center;
  transition: all 0.3s;
}

.summary-schedule-button:hover {
  border-color: #1976d2;
}

.drop-down-webinar-options {
  cursor: 'pointer';
}

.p-container {
  padding-bottom: 5px;
}

#event-manage {
  margin-bottom: 10px;
}

.button-flex {
  display: flex;
  flex-flow: row wrap;
}

.button-flex-space-between {
  justify-content: space-between;
  margin-bottom: 100px;
}

.button-event-types {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: -50px;
  border: 1px solid #dee4e8;
  border-radius: 4px;
  color: #2c3336;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 14px;
  border-color: #dee4e8;
  padding-left: 10px;
  padding-right: 10px;
}

.button-content-span {
  margin-left: 5px;
}

.event-title {
  margin-bottom: 50px;
  text-align: center;
  font-weight: 300;
}

.Title {
  color: #000;
  font-size: 25px;
  font-weight: 500;
  line-height: 36px;
}

.second-step {
  margin-bottom: 32px;
}

.Box-wrapper {
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

.Box {
  background: #fff;
  border-radius: 4px;
  border: 1px solid #eff3f6;
  cursor: pointer;
  height: 96px;
  text-align: left;
  transition: all 0.3s ease;
}

.Box.--no-hover {
  cursor: default;
}

.Box.--open {
  border-bottom: 1px solid #eff3f6;
  /* border-left-style: hidden; */
  border-radius: 4px 4px 0 0;
  /* border-right-style: hidden; */
  border-left-style: hidden;
  border-radius: 4px 4px 0 0;
  border-right-style: hidden;

  border-top: 4px solid #1976d2;
  cursor: pointer;
}

.Box-header {
  align-items: center;
  border-width: 0 1px;
  display: flex;
  padding: 25px;
}

.Box-info {
  margin-left: 10px;
}

.Box-title {
  color: #2c3336;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  padding-right: 20px;
}

.Box-border {
  background: #fff;
  border-radius: 0 0 4px 4px;
}

.Box-border.border {
  background: #fff;
  border-radius: 0 0 4px 4px;
  /* border-right: 1px solid #eff3f6;
  border-left: 1px solid #eff3f6; */
  border-bottom: 1px solid #eff3f6;
  border-radius: 0 0 4px 4px;
}

.Box-content {
  background: #fff;
  border-radius: 0 0 4px 4px;
  margin: auto;
  padding: 40px;
  width: 80%;
}

.Box-content-advanced {
  background: #fff;
  border-radius: 0 0 4px 4px;
  width: 100%;
}
.Box-content-advanced.--hide {
  display: none;
}

.Box-content.--hide {
  display: none;
}

.summary-schedule.--hide {
  display: none;
}

.form-row {
  zoom: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-left: 0;
  margin-right: 0;
  position: relative;
}

.form-item {
  font-feature-settings: 'tnum';
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;

  margin: 0 0 15px;
  padding: 0;
  vertical-align: top;
  font-size: 16px;
}

.form-row.form-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.event-host-label {
  margin: 0;
  padding: 0;
  vertical-align: top;
}

/* .event-host-label {
  padding: 10px 0;
} */

.create-name-bottom {
  border-top: 1px solid #e8ecf3;
  padding: 20px;
  justify-content: center;
  display: flex;
  flex-flow: row wrap;
}

.continue-button {
  cursor: pointer;
  font-size: 16px;
  background: #1976d2;
  color: #fff;
  border: 1px solid #1976d2;
  padding: 5px 10px;
  border-radius: 5px;
}

.customize-list-icon {
  color: #1976d2;
  margin-right: 25px;
}

.Box-hint {
  color: #6c737a;
  font-size: 14px;
  padding-top: 5px;
}

.date-time-picker-container {
  display: flex;
  width: 100%;

  justify-content: center;
  align-content: center;
}
.time-picker-container.end-time {
  margin-top: 30px;
}

.time-picker-container {
  width: 240px;
  margin-right: 20px;
}

.date-time-form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 40px 200px;
  /* background: #fafafa; */
  border-radius: 5px;
  height: 400px;
  overflow: hidden;
}

.date-time-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-bottom: 20px;
}

@media (max-width: 980px) {
  .date-time-container {
    display: flex;
    flex-direction: column;
  }
}
.date-picker-container {
  width: 240px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 980px) {
  .date-picker-container,
  .time-picker-container {
    padding-left: 0;
    padding-right: 0;
  }
}

.date-time-button {
  color: #fff;
  background: #1976d2;
  border-radius: 5px;
  align-items: center;
  border-width: 1px;
  box-shadow: none;
  display: inline-flex;
  font-size: 13px;
  font-weight: 700;
  font-weight: 400;
  height: 40px;
  justify-content: center;
  letter-spacing: 1px;
  line-height: 37px;
  padding: 0 16px;
  text-shadow: none;
}

.date-time-button:hover {
  background: rgba(25, 118, 210, 0.85);
}

.date-time-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #2c3336;
  text-align: center;
  margin-bottom: 20px;
}

.date-time-title,
.date-time-button,
.date-picker-container,
.time-picker-container {
  min-width: 215px;
}

.start-end-container {
  display: flex;
  flex-direction: row;
}

.bottom-radius {
  border-radius: 0 0 6px 6px;
}

.customize-child-container {
  zoom: 1;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.tabs-container {
  display: flex;
  flex-direction: column;
}

.resources-container {
  width: 100%;
}

.advanced-button-container {
  display: flex;
  align-items: center;
  padding-bottom: 25px;
  border-bottom: 1px dashed #e8ecf3;
  width: 100%;
  color: #2c3336;
}

.advanced-info {
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #2c3336;
}

.advanced-title {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  word-break: break-all;
  width: 100%;
  color: #2c3336;
}

.advanced-subtitle {
  font-size: 12px;
  color: rgba(131, 139, 151, 0.7);
  inline-size: 90%;
  width: 100%;
}

.pagination-container {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0;
}

.resource-form-container {
  width: 100%;
  padding: 0 10px 0 10px;
}

.options-label {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-bottom: 5px;
}

.form-input-field {
  margin-bottom: 24px;
}

.options-container {
  display: flex;
  flex-direction: column;
}

.question-field {
  display: flex;
  flex-direction: column;
}

.form-title {
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600 !important;
  letter-spacing: 0.25px !important;
  color: #353d41;
  cursor: default;
}

.form-title-container {
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #e8ecf3;
}

.webinar-description-container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 30px;
}

.host-name-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 12vw;
  font-size: 1.6vw;
  background: #353d41;
  border-radius: 5px;
  color: white;
}

.form-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.admin-form-button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding-bottom: 20px;
}

.form-field-container {
  margin: 30px 0;
  min-width: 215px;
  margin-right: 20px;
}

.registration-box-container {
  display: flex;
  position: relative;
  padding: 50px;
  align-items: center;
  justify-content: center;
}

.registration-box {
  flex: 1;
  max-width: 220px;
  position: relative;
}

.registration-box-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid #e8ecf3;
  /* border-bottom: none; */
  /* border-bottom: none; */
  padding: 30px;
  /* height: 150px; */
  border-radius: 5px;
  /* height: 100%; */
  /* margin-bottom: 20px; */
}

.registration-title {
  color: #42454b;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 14px;
}

.banner-container {
  text-align: center;
}

@media (max-width: 767px) {
  .banner-container {
    text-align: center;
    height: 100px;
  }
}

.registration-banner-image {
  max-height: 200px;
  max-width: 640px;
  border-radius: 8px;
}

.tab-panel-registration {
  width: 100%;
}

.ripple-button.registration {
  /* border-radius: 4px; */
  border: none;
  margin: 0;
  padding: 9px 12px;
  background: #1976d2;
  color: #fff;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  height: 45px;
}

.registration-page-editor {
  display: flex;
  flex-direction: row;
  min-height: 100%;
  width: 100%;
}

.editor-column-page {
  width: 325px;
  background-color: #fff;
  box-shadow: 3px 0 10px 0 rgba(0, 0, 0, 0.1);
  z-index: 5;
}
.registration-form-outer {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 50px 50px 75px 50px;
  border: 1px solid #fff;
  border-radius: 5px;
  justify-content: center;
  width: 90%;
}

.registration-demo-page {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-height: 430px;
  position: relative;
  word-break: break-word;
  margin-left: 40px;
  width: 100%;
}

.registration-page-topic {
  font-size: 30px;
  color: black;
  margin-bottom: 26px;
  margin-top: 20px;
  font-weight: 500;
  border-bottom: 1px solid #e8ecf3;
  padding-bottom: 30px;
}

.registration-button-demo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
}

.registration-inner-container {
  display: flex;
  flex-direction: row;
}

.registration-image-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #fff;
  margin-bottom: 20px;
  align-self: center;
}

.date-time-registration {
  color: black;
  margin-right: 50px;
  max-width: 86px;
  white-space: nowrap;
}

.editor-title {
  padding: 25px;
  font-weight: 500;
  border-bottom: 1px solid #e2e2e2;
}

.logo-registration-page {
  width: 1vw;
  height: 1vw;
  margin-left: 5px;
  border-radius: 50%;
}

.registration-save-button {
  width: calc(100% - 40px);
  border: 0;
  font-size: 13px;
  font-weight: 600;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
  margin: 20px;
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
  -webkit-transition: background-color 0.4s;
  transition: background-color 0.4s;
}

.registration-save-button:hover {
  width: calc(100% - 40px);
  border: 0;
  font-size: 13px;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 5px;
  margin: 20px;
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
  -webkit-transition: background-color 0.4s;
  transition: background-color 0.4s;
}

.register-form-menu {
  display: flex;
  flex-direction: column;
  width: 106%;
  max-height: 500px;
  overflow-y: scroll;
}
.register-form-menu::-webkit-scrollbar {
  display: none;
}

.tags-input-field {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.confirmation-page-demo {
  display: flex;
  max-width: 100%;
  flex-direction: column;
  background: #fff;
  padding: 100px 50px 100px 50px;
  border: 1px solid #fff;
  border-radius: 5px;
  align-items: center;
  height: 100%;
}

.confirmation-page-demo-form {
  border-radius: 5px;
  padding: 10px 20px;
  width: 80%;
  background: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.4);
}

.demo-page-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 35px;
  color: black;
  margin-bottom: 100px;
  margin-top: 20px;
  font-weight: 500;
  padding-bottom: 10px;
}

.additional-msg-container {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 40px;
}

.additional-msg {
  display: flex;
  color: black;
  padding: 20px 40px 20px 40px;
  font-size: 20px;
}

.confirmation-demo-label-col {
  width: 28%;
  color: #282c34;
}

/* .register-form-container {
  width: 95%;
  padding-right: 20px;
  padding-left: 20px;
  max-height: 500px;
  overflow-y: auto;
} */
.thing {
  height: 400px;
  justify-content: center;
  align-content: center;
}

.slimInput {
  padding: 1px !important;
}
.slimInput div {
  padding: 0px !important;
}
.slimInput div input {
  padding: 7px !important;
}

.navLink {
  cursor: pointer;
  font-size: 18px;
  position: relative;
  top: 6px;
  padding-top: 4px;
  padding-bottom: 16px;
  margin: 0px;
  padding-left: 12px;
}

.resource-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: height 5s ease-out;
}

.resource-item-container {
  padding: 10px;
  margin: 0 10px;
  outline: none;
}

.resource-item {
  display: flex;
  padding: 10px;
  margin: 0 10px;
  border: 1px solid #e8ecf3;
  border-radius: 8px;
}

.resource-icon {
  margin-left: 10px;
  margin-right: 25px;
}

.resource-info {
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.edit-resource {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 25px;
}

.delete-resource {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.resource-title-info {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  word-break: break-all;
}

.resource-type-info {
  font-size: 12px;
  color: rgba(131, 139, 151, 0.7);
  inline-size: 90%;
}

.edit-resource-button {
  color: #3b98df;
  background-color: transparent;
  border: none;
}

.update-form-title {
  display: flex;
  padding: 10px 0px;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.update-title {
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600 !important;
  letter-spacing: 0.25px !important;
  color: #353d41;
}

.resource-form-open {
  background: rgba(25, 118, 210, 0.85);
  color: white;
}

.empty-resources {
  text-align: center;
  display: flex;
  justify-content: center;
  width: auto;
  height: auto;
  font-size: 14.5px;
  padding: 55px 0;
}

.empty-resources-info {
  align-items: center;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 150px;
  font-size: 15px;
}

.empty-resources-disclaimer {
  margin: auto 0 auto 15px;
}

.subcategory-option-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.input-info-wrapper {
  display: flex;
  justify-content: flex-start;
}

.pre-post-container {
  padding: 40px;
}

.pre-settings-container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed #eff3f6;
  margin-bottom: 18px;
  padding-bottom: 20px;
}

.pre-post-row {
  zoom: 1;
  box-sizing: border-box;
  display: block;
  height: auto;
  margin-left: 0;
  margin-right: 0;
  position: relative;
}

.pre-post-label {
  margin: 0 0 10px 0;
}

.invisible-submit-button {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  z-index: -9999px;
}

.description-form-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  box-sizing: border-box;
}

.logo-placeholder-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.first-webinar-walkthrough {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  font-size: 20px;
}

.next-step-container {
  display: flex;
  justify-content: flex-start;
  padding: 0 10px;
  margin: 0;
}

.next-step-link {
  display: flex;
  align-items: center;
}

.next-step-link-text {
  color: #1976d2;
  cursor: pointer;
}

.snack-icon-message {
  display: flex;
  align-items: flex-end;
}

.google-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.login-google-btn {
  width: 100%;
  height: 49px;
  cursor: pointer;
  color: #1976d2;
  border-radius: 50px;
  border: 1px solid black;
  font-size: 16px;
  color: black;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-content-container {
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  width: 100%;
  margin: 0;
}

.delete-modal-title-btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px solid #e8ecf3;
}

.delete-modal-info-container {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.delete-modal-info {
  display: flex;
  flex-direction: column;
}

.delete-items-list {
  list-style-type: disc;
}

.delete-webinar-btns {
  display: flex;
  justify-content: flex-end;
}

.pre-party-question-container {
  width: 100%;
  margin-left: 20px;
}

.pre-post-btn-container {
  display: flex;
  justify-content: center;
}

.email-reminder-item {
  display: flex;
  align-items: center;
  padding: 20px 0;
  margin: 0 32px;
  border-top: 1px dashed #e8ecf3;
}

.email-reminder-type {
  flex: 1;
}

.admin-form-title {
  display: flex;
  justify-content: center;
  padding: 20px;
  color: #2c3336;
}

.admin-form-container {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-wrap-register {
  padding: 0;
  margin: 0;
}

.register-form {
  margin: 64px auto 24px;
  width: 720px;
  padding: 0 40px;
  background: #fff;
  border-radius: 16px;
  border: 1px solid #ededf4;
}

.register-form-info {
  margin-top: 24px;
}

.banner-image-container {
  text-align: center;
}

.register-banner-image {
  max-height: 200px;
  max-width: 640px;
  border-radius: 8px;
}

.title-detail {
  margin-top: 32px;
}

.on-demand-topic {
  display: block;
  max-width: 100%;
}

.register-content-wrapper {
  border-top: 1px solid #ededf4;
  margin-top: 32px;
  padding-top: 24px;
  padding-bottom: 32px;
}

.register-content {
  display: flex;
}

.register-info {
  padding-right: 16px;
  width: calc(100% - 120px);
}

.register-logo-container {
  margin: 0;
  padding: 0;
}
.register-logo {
  max-width: 120px;
  width: 100%;
  height: auto;
}

.register-info-row:before {
  display: table;
  content: '';
}

.register-info-row {
  margin-bottom: 16px !important;
  position: relative;
  box-sizing: border-box;
}

.register-info-row:after {
  clear: both;
  display: table;
  content: '';
}

.register-info-column-left {
  line-height: 24px;
  width: 25%;
  box-sizing: border-box;
  float: left;
}

.register-label {
  font-weight: bold;
}

.register-info-column-right {
  word-break: break-word;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding-left: 4px !important;
  width: 75%;
  box-sizing: border-box;
  float: left;
}

.register-btn-container {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.share-container {
  background-color: #fff;
  border: 1px solid #e8ecf3;
  border-radius: 5px;
}

.share-tabs {
  padding-left: 15px;
  display: flex;
  height: 72px;
  padding-top: 20px;
  /* border-bottom: 1px solid #e8ecf3; */
}

.share-content {
  padding: 60px;
}

.register-success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
}

.add-to-calendar-container {
  display: flex;
  justify-content: space-between;
}

.optional-msg-confirmation {
  padding-top: 15px;
}